const dict = {
  paysimply: `.cx-widget.cx-theme-light {
    color: #444A52;
    background-color: #FDFDFD;
    scrollbar-face-color: #CCCCCC;
}
.cx-widget.cx-theme-light .cx-theme {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-overlay {
    background-color: #FDFDFD;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone1 {
    fill: #d52b1e !important;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone2 {
    fill: #8C8C8C;
}
.cx-widget.cx-theme-light .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
}
.cx-widget.cx-theme-light .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
}
.cx-widget.cx-theme-light * {
    border-color: #DAE1E8;
}
.cx-widget.cx-theme-light .cx-titlebar .cx-title {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #8795A6;
}
.cx-widget.cx-theme-light .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
}
.cx-widget.cx-theme-light .cx-input-group-btn .cx-svg-icon-tone1 {
    fill: #FDFDFD;
}
.cx-widget.cx-theme-light label {
    color: #444A52;
}
.cx-widget.cx-theme-light a {
    color: white;
}
.cx-widget.cx-theme-light a:hover {
    color: #000000;
}
.cx-widget.cx-theme-light .cx-icon-alert-circle {
    color: #F53131;
}
.cx-widget.cx-theme-light .cx-footer, .cx-widget.cx-theme-light .cx-branding {
    color: #66759B;
}
.cx-widget.cx-theme-light .cx-footer *, .cx-widget.cx-theme-light .cx-branding * {
    fill: #66759B;
}
.cx-widget.cx-theme-light .cx-form-control.cx-error {
    border-color: #F53131;
}
.cx-widget.cx-theme-light .cx-form-control::placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control:-moz-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control::-moz-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control:-ms-input-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control::-webkit-input-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light input:focus, .cx-widget.cx-theme-light textarea:focus, .cx-widget.cx-theme-light .cx-btn:focus, .cx-widget.cx-theme-light .cx-button-group button:focus, .cx-widget.cx-theme-light .cx-form-control:not(.cx-error):focus {
    border-color: #5791FD !important;
}
.cx-widget.cx-theme-light input[type="text"], .cx-widget.cx-theme-light input[type="email"], .cx-widget.cx-theme-light input[type="tel"], .cx-widget.cx-theme-light select, .cx-widget.cx-theme-light textarea {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #94948A;
}
.cx-widget.cx-theme-light .cx-btn-default {
    background: none;
    border-color: #DAE1E8 !important;
    color: #FDFDFD;
    background-color: #232222 !important;
}
.cx-widget.cx-theme-light .cx-btn.cx-disabled {
    background: #CCCCCC;
}
.cx-widget.cx-theme-light .cx-btn-primary {
    color: #FDFDFD;
    border-color: transparent !important;
    background: #26446b !important;
    background: -moz-linear-gradient(top,#26446b 0%,#26446b 100%) !important;
    background: -webkit-linear-gradient(top,#26446b 0%,#26446b 100%) !important;
    background: linear-gradient(to bottom,#26446b 0%,#26446b 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0) !important;
    background-color: #26446b !important;
}
.cx-widget.cx-theme-light .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-button-group .cx-btn-default:focus, .cx-widget.cx-theme-light .cx-btn-default:focus {
    border-color: #000 !important;
}
.cx-widget.cx-theme-light .cx-ac-dropdown-menu {
    background: #FDFDFD;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-ac-suggestion:hover, .cx-widget.cx-theme-light .cx-ac-suggestion.cx-active {
    background-color: #5791FD;
    color: #FDFDFD;
}
.cx-widget.cx-theme-light ::-webkit-scrollbar-thumb {
    background: #939399;
}
.cx-widget.cx-theme-light .cx-smokescreen {
    background-color: #FDFDFD;
    opacity: 0.7;
}
.cx-widget.cx-theme-light .cx-dialog-container .cx-dialog {
    background-color: #FDFDFD;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-theme-background {
    background-color: #FDFDFD;
}
.cx-widget.cx-theme-light .cx-theme-border {
    border: 1px solid #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-theme-text {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #d52b1e;
}
.cx-widget.cx-theme-light .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #34A84F;
}
.cx-widget.cx-theme-light .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #d52b1e !important;
}
.cx-widget.cx-theme-light .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
}
.cx-widget.cx-theme-light .cx-warning {
    color: #F53131 !important;
}
.cx-widget.cx-theme-light .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
}
.cx-widget.cx-theme-light .cx-countdown {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-countdown .cx-icon svg * {
    fill: #444A52;
}
.cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-name, .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-message-text, .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-time {
    color: white !important;
    opacity: 0.9;
}
.cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-time {
    opacity: 0.8 !important;
}
.cx-loading-screen .cx-loading-icon svg g g, .cx-loading-container .cx-loading-icon svg g g {
    stroke: #2B74FF !important;
}
.cx-loading-screen .cx-loading-icon svg g:first-child g, .cx-loading-container .cx-loading-icon svg g:first-child g {
    stroke: #72799D !important;
}
.cx-message.cx-them .cx-bubble {
    background-color: #d52b1e !important;
}
.cx-message.cx-them .cx-bubble-arrow svg * {
    fill: #d52b1e !important;
}
.cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-agent .cx-time {
    opacity: 0.8 !important;
}
.cx-common-container .cx-titlebar .cx-title {
    font-weight: 300 !important;
}
.cx-powered-by, .cx-branding-icon {
    display: none !important;
}
.cx-avatar.agent {
    color: #d52b1e;
}
.cxPS {
    display: none;
}
.cx-message-text a {
    color: white;
}





/*//////////////////*/


.cx-widget.cx-theme-dark {
    color: #FDFDFD;
    background-color: #33383D;
    scrollbar-face-color: #A3A8AE;
    scrollbar-track-color: #212529;
    scrollbar-arrow-color: #A3A8AE;
}
.cx-widget.cx-theme-dark .cx-theme {
    background-color: #33383D;
    color: #FDFDFD;
    border-color: #b1b1b1 !important;
}
.cx-widget.cx-theme-dark .cx-overlay {
    background-color: #33383D;
}
.cx-widget.cx-theme-dark .cx-svg-icon-tone1 {
    fill: #b1b1b1;
}
.cx-widget.cx-theme-dark .cx-svg-icon-tone2 {
    fill: #8C8C8C;
}
.cx-widget.cx-theme-dark .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
}
.cx-widget.cx-theme-dark .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
}
.cx-widget.cx-theme-dark * {
    border-color: #b1b1b1;
}
.cx-widget.cx-theme-dark .cx-titlebar .cx-title {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #98A7B8;
}
.cx-widget.cx-theme-dark .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
}
.cx-widget.cx-theme-dark label {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark a {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark a:hover {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-icon-alert-circle {
    color: #F53131;
}
.cx-widget.cx-theme-dark .cx-footer, .cx-widget.cx-theme-dark .cx-branding {
    color: #98A7B8;
}
.cx-widget.cx-theme-dark .cx-footer *, .cx-widget.cx-theme-dark .cx-branding * {
    fill: #98A7B8;
}
.cx-widget.cx-theme-dark .cx-form-control {
    background-color: #444A52;
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-form-control.cx-error {
    border-color: #F53131 !important;
}
.cx-widget.cx-theme-dark .cx-form-control::placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark .cx-form-control:-moz-placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark .cx-form-control::-moz-placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark .cx-form-control:-ms-input-placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark .cx-form-control::-webkit-input-placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark input:focus, .cx-widget.cx-theme-dark textarea:focus, .cx-widget.cx-theme-dark .cx-btn:focus, .cx-widget.cx-theme-dark .cx-button-group button:focus, .cx-widget.cx-theme-dark .cx-form-control:not(.cx-error):focus {
    border-color: #75A8FF !important;
}
.cx-widget.cx-theme-dark input, .cx-widget.cx-theme-dark select, .cx-widget.cx-theme-dark textarea {
    background-color: #444A52;
    color: #FDFDFD;
    border-color: #758384;
}
.cx-widget.cx-theme-dark * {
    border-color: #b1b1b1;
}
.cx-widget.cx-theme-dark .cx-btn-default {
    background: none;
    border-color: #b1b1b1 !important;
    color: #FDFDFD;
    background-color: #232222;
}
.cx-widget.cx-theme-dark .cx-btn.cx-disabled {
    background: #CCCCCC;
}
.cx-widget.cx-theme-dark .cx-btn-primary {
    color: #FDFDFD;
    border-color: #2b4e7b !important;
    background: #2b4e7b;
    background: -moz-linear-gradient(top,#2b4e7b 0%,#2b4e7b 100%);
    background: -webkit-linear-gradient(top,#2b4e7b 0%,#2b4e7b 100%);
    background: linear-gradient(to bottom,#2b4e7b 0%,#2b4e7b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0);
}
.cx-widget.cx-theme-dark .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-dark .cx-btn-primary:focus {
    border-color: #DAE6FC !important;
}
.cx-widget.cx-theme-dark .cx-ac-dropdown-menu {
    background: #33383D;
    border-color: #b1b1b1 !important;
}
.cx-widget.cx-theme-dark .cx-ac-suggestion:hover, .cx-widget.cx-theme-dark .cx-ac-suggestion.cx-active {
    background-color: #75A8FF;
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark ::-webkit-scrollbar-thumb {
    background: #A3A8AE;
}
.cx-widget.cx-theme-dark .cx-smokescreen {
    background-color: #33383D;
    opacity: 0.7;
}
.cx-widget.cx-theme-dark .cx-dialog-container .cx-dialog {
    background-color: #33383D;
    border-color: #b1b1b1 !important;
}
.cx-widget.cx-theme-dark .cx-theme-background {
    background-color: #33383D;
}
.cx-widget.cx-theme-dark .cx-theme-border {
    border: 1px solid #b1b1b1 !important;
}
.cx-widget.cx-theme-dark .cx-theme-text {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #2b4e7b;
}
.cx-widget.cx-theme-dark .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #4AC764;
}
.cx-widget.cx-theme-dark .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #F53131;
}
.cx-widget.cx-theme-dark .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
}
.cx-widget.cx-theme-dark .cx-warning {
    color: #F53131 !important;
}
.cx-widget.cx-theme-dark .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
}
.cx-widget.cx-theme-dark .cx-countdown {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-countdown .cx-icon svg * {
    fill: #FDFDFD;
}
`,

  nowprepay: `.cx-widget.cx-theme-light {
    color: #444A52;
    background-color: #FDFDFD;
    scrollbar-face-color: #CCCCCC;
  }
  .cx-widget.cx-theme-light .cx-theme {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-overlay {
    background-color: #FDFDFD;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-tone1 {
    fill: #f68d1e !important;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-tone2 {
    fill: #8C8C8C;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
  }
  .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-name, .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-message-text, .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-time {
    color: white !important;
    opacity: 0.9;
  }
  .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-time {
    opacity: 0.7 !important;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
  }
  .cx-widget.cx-theme-light * {
    border-color: #DAE1E8;
  }
  .cx-widget.cx-theme-light .cx-titlebar .cx-title {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #8795A6;
  }
  .cx-widget.cx-theme-light .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-light .cx-input-group-btn .cx-svg-icon-tone1 {
    fill: #FDFDFD;
  }
  .cx-widget.cx-theme-light label {
    color: #444A52;
  }
  .cx-widget.cx-theme-light a {
    color: white;
  }
  .cx-widget.cx-theme-light a:hover {
    color: white;
  }
  .cx-widget.cx-theme-light .cx-icon-alert-circle {
    color: #F53131;
  }
  .cx-widget.cx-theme-light .cx-footer, .cx-widget.cx-theme-light .cx-branding {
    color: #66759B;
  }
  .cx-widget.cx-theme-light .cx-footer *, .cx-widget.cx-theme-light .cx-branding * {
    fill: #66759B;
  }
  .cx-widget.cx-theme-light .cx-form-control.cx-error {
    border-color: #F53131;
  }
  .cx-widget.cx-theme-light .cx-form-control::placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control:-moz-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control::-moz-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control:-ms-input-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control::-webkit-input-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light input:focus, .cx-widget.cx-theme-light textarea:focus, .cx-widget.cx-theme-light .cx-btn:focus, .cx-widget.cx-theme-light .cx-button-group button:focus, .cx-widget.cx-theme-light .cx-form-control:not(.cx-error):focus {
    border-color: #5791FD !important;
  }
  .cx-widget.cx-theme-light input[type="text"], .cx-widget.cx-theme-light input[type="email"], .cx-widget.cx-theme-light input[type="tel"], .cx-widget.cx-theme-light select, .cx-widget.cx-theme-light textarea {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #94948A;
  }
  .cx-widget.cx-theme-light .cx-btn-default {
    background: none;
    border-color: #DAE1E8 !important;
    color: #FDFDFD;
    background-color: #212121 !important;
  }
  .cx-widget.cx-theme-light .cx-btn.cx-disabled {
    background: #CCCCCC;
  }
  .cx-widget.cx-theme-light .cx-btn-primary {
    color: white;
    border-color: transparent !important;
    background: #4372b8 !important;
    background: -moz-linear-gradient(top,#4372b8 0%,#4372b8 100%) !important;
    background: -webkit-linear-gradient(top,#4372b8 0%,#4372b8 100%) !important;
    background: linear-gradient(to bottom,#4372b8 0%,#4372b8 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0) !important;
    background-color: #4372b8 !important;
  }
  .cx-widget.cx-theme-light .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-button-group .cx-btn-default:focus, .cx-widget.cx-theme-light .cx-btn-default:focus {
    border-color: #000 !important;
  }
  .cx-widget.cx-theme-light .cx-ac-dropdown-menu {
    background: #FDFDFD;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-ac-suggestion:hover, .cx-widget.cx-theme-light .cx-ac-suggestion.cx-active {
    background-color: #5791FD;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-light ::-webkit-scrollbar-thumb {
    background: #939399;
  }
  .cx-widget.cx-theme-light .cx-smokescreen {
    background-color: #FDFDFD;
    opacity: 0.7;
  }
  .cx-widget.cx-theme-light .cx-dialog-container .cx-dialog {
    background-color: #FDFDFD;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-background {
    background-color: #FDFDFD;
  }
  .cx-widget.cx-theme-light .cx-theme-border {
    border: 1px solid #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-text {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #4372b8;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #34A84F;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #4372b8 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
  }
  .cx-widget.cx-theme-light .cx-warning {
    color: #F53131 !important;
  }
  .cx-widget.cx-theme-light .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
  }
  .cx-widget.cx-theme-light .cx-countdown {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-countdown .cx-icon svg * {
    fill: #444A52;
  }
  .cx-loading-screen .cx-loading-icon svg g g, .cx-loading-container .cx-loading-icon svg g g {
    stroke: #2B74FF !important;
  }
  .cx-loading-screen .cx-loading-icon svg g:first-child g, .cx-loading-container .cx-loading-icon svg g:first-child g {
    stroke: #72799D !important;
  }
  .cx-message.cx-them .cx-bubble {
    background-color: #4372b8 !important;
  }
  .cx-message.cx-them .cx-bubble-arrow svg * {
    fill: #4372b8 !important;
  }
  .cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-agent .cx-time {
    opacity: 0.8 !important;
  }
  .cx-common-container .cx-titlebar .cx-title {
    font-weight: 300 !important;
  }
  .cx-powered-by, .cx-branding-icon {
    display: none !important;
  }
  .cxPS {
    display: none;
  }
  
  
  
  
  
  
  .cx-widget.cx-theme-dark {
    color: #FDFDFD;
    background-color: #33383D;
    scrollbar-face-color: #A3A8AE;
    scrollbar-track-color: #212529;
    scrollbar-arrow-color: #A3A8AE;
  }
  .cx-widget.cx-theme-dark .cx-theme {
    background-color: #33383D;
    color: #FDFDFD;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-overlay {
    background-color: #33383D;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-tone1 {
    fill: #f68d1e;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-tone2 {
    fill: #8C8C8C;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
  }
  .cx-widget.cx-theme-dark * {
    border-color: #b1b1b1;
  }
  .cx-widget.cx-theme-dark .cx-titlebar .cx-title {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark label {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark a {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark a:hover {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-icon-alert-circle {
    color: #F53131;
  }
  .cx-widget.cx-theme-dark .cx-footer, .cx-widget.cx-theme-dark .cx-branding {
    color: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-footer *, .cx-widget.cx-theme-dark .cx-branding * {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-form-control {
    background-color: #444A52;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-form-control.cx-error {
    border-color: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-form-control::placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control:-moz-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control::-moz-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control:-ms-input-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control::-webkit-input-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark input:focus, .cx-widget.cx-theme-dark textarea:focus, .cx-widget.cx-theme-dark .cx-btn:focus, .cx-widget.cx-theme-dark .cx-button-group button:focus, .cx-widget.cx-theme-dark .cx-form-control:not(.cx-error):focus {
    border-color: #75A8FF !important;
  }
  .cx-widget.cx-theme-dark input, .cx-widget.cx-theme-dark select, .cx-widget.cx-theme-dark textarea {
    background-color: #444A52;
    color: #FDFDFD;
    border-color: #758384;
  }
  .cx-widget.cx-theme-dark .cx-btn-default {
    background: none;
    border-color: #b1b1b1 !important;
    color: #FDFDFD;
    background-color: #212121;
  }
  .cx-widget.cx-theme-dark .cx-btn.cx-disabled {
    background: #CCCCCC;
  }
  .cx-widget.cx-theme-dark .cx-btn-primary {
    color: black;
    border-color: #f68d1e !important;
    background: #f68d1e;
    background: -moz-linear-gradient(top,#f68d1e 0%,#f68d1e 100%);
    background: -webkit-linear-gradient(top,#f68d1e 0%,#f68d1e 100%);
    background: linear-gradient(to bottom,#f68d1e 0%,#f68d1e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0);
  }
  .cx-widget.cx-theme-dark .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-dark .cx-btn-primary:focus {
    border-color: #DAE6FC !important;
  }
  .cx-widget.cx-theme-dark .cx-ac-dropdown-menu {
    background: #33383D;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-ac-suggestion:hover, .cx-widget.cx-theme-dark .cx-ac-suggestion.cx-active {
    background-color: #75A8FF;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark ::-webkit-scrollbar-thumb {
    background: #A3A8AE;
  }
  .cx-widget.cx-theme-dark .cx-smokescreen {
    background-color: #33383D;
    opacity: 0.7;
  }
  .cx-widget.cx-theme-dark .cx-dialog-container .cx-dialog {
    background-color: #33383D;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-theme-background {
    background-color: #33383D;
  }
  .cx-widget.cx-theme-dark .cx-theme-border {
    border: 1px solid #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-theme-text {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #f68d1e;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #4AC764;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #F53131;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
  }
  .cx-widget.cx-theme-dark .cx-warning {
    color: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-countdown {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-countdown .cx-icon svg * {
    fill: #FDFDFD;
  }
  `,

  topmeup: `.cx-widget.cx-theme-light {
    color: #444A52;
    background-color: #FDFDFD;
    scrollbar-face-color: #CCCCCC;
}
.cx-widget.cx-theme-light .cx-theme {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-overlay {
    background-color: #FDFDFD;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone1 {
    fill: #448203 !important;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone2 {
    fill: #8C8C8C;
}
.cx-widget.cx-theme-light .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
}
.cx-widget.cx-theme-light .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
}
.cx-widget.cx-theme-light * {
    border-color: #DAE1E8;
}
.cx-widget.cx-theme-light .cx-titlebar .cx-title {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #8795A6;
}
.cx-widget.cx-theme-light .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
}
.cx-widget.cx-theme-light .cx-input-group-btn .cx-svg-icon-tone1 {
    fill: #FDFDFD;
}
.cx-widget.cx-theme-light label {
    color: #444A52;
}
.cx-widget.cx-theme-light a {
    color: white;
}
.cx-widget.cx-theme-light a:hover {
    color: white;
}
.cx-widget.cx-theme-light .cx-icon-alert-circle {
    color: #F53131;
}
.cx-widget.cx-theme-light .cx-footer, .cx-widget.cx-theme-light .cx-branding {
    color: #66759B;
}
.cx-widget.cx-theme-light .cx-footer *, .cx-widget.cx-theme-light .cx-branding * {
    fill: #66759B;
}
.cx-widget.cx-theme-light .cx-form-control.cx-error {
    border-color: #F53131;
}
.cx-widget.cx-theme-light .cx-form-control::placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control:-moz-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control::-moz-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control:-ms-input-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control::-webkit-input-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light input:focus, .cx-widget.cx-theme-light textarea:focus, .cx-widget.cx-theme-light .cx-btn:focus, .cx-widget.cx-theme-light .cx-button-group button:focus, .cx-widget.cx-theme-light .cx-form-control:not(.cx-error):focus {
    border-color: #5791FD !important;
}
.cx-widget.cx-theme-light input[type="text"], .cx-widget.cx-theme-light input[type="email"], .cx-widget.cx-theme-light input[type="tel"], .cx-widget.cx-theme-light select, .cx-widget.cx-theme-light textarea {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #94948A;
}
.cx-widget.cx-theme-light .cx-btn-default {
    background: none;
    border-color: #DAE1E8 !important;
    color: #FDFDFD;
    background-color: #212121 !important;
}
.cx-widget.cx-theme-light .cx-btn.cx-disabled {
    background: #CCCCCC;
}
.cx-widget.cx-theme-light .cx-btn-primary {
    color: #FDFDFD;
    border-color: transparent !important;
    background: #448203 !important;
    background: -moz-linear-gradient(top,#448203 0%,#448203 100%) !important;
    background: -webkit-linear-gradient(top,#448203 0%,#448203 100%) !important;
    background: linear-gradient(to bottom,#448203 0%,#448203 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0) !important;
    background-color: #448203 !important;
}
.cx-widget.cx-theme-light .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-button-group .cx-btn-default:focus, .cx-widget.cx-theme-light .cx-btn-default:focus {
    border-color: #000 !important;
}
.cx-widget.cx-theme-light .cx-ac-dropdown-menu {
    background: #FDFDFD;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-ac-suggestion:hover, .cx-widget.cx-theme-light .cx-ac-suggestion.cx-active {
    background-color: #5791FD;
    color: #FDFDFD;
}
.cx-widget.cx-theme-light ::-webkit-scrollbar-thumb {
    background: #939399;
}
.cx-widget.cx-theme-light .cx-smokescreen {
    background-color: #FDFDFD;
    opacity: 0.7;
}
.cx-widget.cx-theme-light .cx-dialog-container .cx-dialog {
    background-color: #FDFDFD;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-theme-background {
    background-color: #FDFDFD;
}
.cx-widget.cx-theme-light .cx-theme-border {
    border: 1px solid #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-theme-text {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #448203;
}
.cx-widget.cx-theme-light .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #34A84F;
}
.cx-widget.cx-theme-light .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #448203 !important;
}
.cx-widget.cx-theme-light .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
}
.cx-widget.cx-theme-light .cx-warning {
    color: #F53131 !important;
}
.cx-widget.cx-theme-light .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
}
.cx-widget.cx-theme-light .cx-countdown {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-countdown .cx-icon svg * {
    fill: #444A52;
}
.cx-them .cx-name {
    color: white !important;
}
.cx-them .cx-message-text {
    color: white !important;
}
.cx-them .cx-time {
    color: white !important;
}
.cx-loading-screen .cx-loading-icon svg g g, .cx-loading-container .cx-loading-icon svg g g {
    stroke: #2B74FF !important;
}
.cx-loading-screen .cx-loading-icon svg g:first-child g, .cx-loading-container .cx-loading-icon svg g:first-child g {
    stroke: #72799D !important;
}
.cx-message.cx-them .cx-bubble {
    background-color: #448203 !important;
}
.cx-message.cx-them .cx-bubble-arrow svg * {
    fill: #448203 !important;
}
.cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-agent .cx-time {
    opacity: 0.8 !important;
}
.cx-common-container .cx-titlebar .cx-title {
    font-weight: 300 !important;
}
.cx-powered-by, .cx-branding-icon {
    display: none !important;
}
.cxPS {
    display: none;
}
`,

  zenwallet: `.cx-widget.cx-theme-light {
    color: #444A52;
    background-color: #FDFDFD;
    scrollbar-face-color: #CCCCCC;
  }
  .cx-widget.cx-theme-light .cx-theme {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-overlay {
    background-color: #FDFDFD;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-tone1 {
    fill: #51822f !important;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-tone2 {
    fill: #8C8C8C;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
  }
  .cx-widget.cx-theme-light * {
    border-color: #DAE1E8;
  }
  .cx-widget.cx-theme-light .cx-titlebar .cx-title {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #8795A6;
  }
  .cx-widget.cx-theme-light .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-light .cx-input-group-btn .cx-svg-icon-tone1 {
    fill: #FDFDFD;
  }
  .cx-widget.cx-theme-light label {
    color: #444A52;
  }
  .cx-widget.cx-theme-light a {
    color: #000000;
  }
  .cx-widget.cx-theme-light a:hover {
    color: #000000;
  }
  .cx-widget.cx-theme-light .cx-icon-alert-circle {
    color: #F53131;
  }
  .cx-widget.cx-theme-light .cx-footer, .cx-widget.cx-theme-light .cx-branding {
    color: #66759B;
  }
  .cx-widget.cx-theme-light .cx-footer *, .cx-widget.cx-theme-light .cx-branding * {
    fill: #66759B;
  }
  .cx-widget.cx-theme-light .cx-form-control.cx-error {
    border-color: #F53131;
  }
  .cx-widget.cx-theme-light .cx-form-control::placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control:-moz-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control::-moz-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control:-ms-input-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control::-webkit-input-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light input:focus, .cx-widget.cx-theme-light textarea:focus, .cx-widget.cx-theme-light .cx-btn:focus, .cx-widget.cx-theme-light .cx-button-group button:focus, .cx-widget.cx-theme-light .cx-form-control:not(.cx-error):focus {
    border-color: #5791FD !important;
  }
  .cx-widget.cx-theme-light input[type="text"], .cx-widget.cx-theme-light input[type="email"], .cx-widget.cx-theme-light input[type="tel"], .cx-widget.cx-theme-light select, .cx-widget.cx-theme-light textarea {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #94948A;
  }
  .cx-widget.cx-theme-light .cx-btn-default {
    background: none;
    border-color: #DAE1E8 !important;
    color: #FDFDFD;
    background-color: #1b1c1d !important;
  }
  .cx-widget.cx-theme-light .cx-btn.cx-disabled {
    background: #CCCCCC;
  }
  .cx-widget.cx-theme-light .cx-btn-primary {
    color: black;
    border-color: transparent !important;
    background: #73b842 !important;
    background: -moz-linear-gradient(top,#73b842 0%,#73b842 100%) !important;
    background: -webkit-linear-gradient(top,#73b842 0%,#73b842 100%) !important;
    background: linear-gradient(to bottom,#73b842 0%,#73b842 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0) !important;
    background-color: #73b842 !important;
  }
  .cx-widget.cx-theme-light .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-button-group .cx-btn-default:focus, .cx-widget.cx-theme-light .cx-btn-default:focus {
    border-color: #000 !important;
  }
  .cx-widget.cx-theme-light .cx-ac-dropdown-menu {
    background: #FDFDFD;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-ac-suggestion:hover, .cx-widget.cx-theme-light .cx-ac-suggestion.cx-active {
    background-color: #5791FD;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-light ::-webkit-scrollbar-thumb {
    background: #939399;
  }
  .cx-widget.cx-theme-light .cx-smokescreen {
    background-color: #FDFDFD;
    opacity: 0.7;
  }
  .cx-widget.cx-theme-light .cx-dialog-container .cx-dialog {
    background-color: #FDFDFD;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-background {
    background-color: #FDFDFD;
  }
  .cx-widget.cx-theme-light .cx-theme-border {
    border: 1px solid #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-text {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #73b842;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #34A84F;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #73b842 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
  }
  .cx-widget.cx-theme-light .cx-warning {
    color: #F53131 !important;
  }
  .cx-widget.cx-theme-light .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
  }
  .cx-widget.cx-theme-light .cx-countdown {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-countdown .cx-icon svg * {
    fill: #444A52;
  }
  .cx-loading-screen .cx-loading-icon svg g g, .cx-loading-container .cx-loading-icon svg g g {
    stroke: #2B74FF !important;
  }
  .cx-loading-screen .cx-loading-icon svg g:first-child g, .cx-loading-container .cx-loading-icon svg g:first-child g {
    stroke: #72799D !important;
  }
  .cx-message.cx-them .cx-bubble {
    background-color: #73b842 !important;
  }
  .cx-message.cx-them .cx-bubble-arrow svg * {
    fill: #73b842 !important;
  }
  .cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-agent .cx-time {
    opacity: 0.8 !important;
  }
  .cx-common-container .cx-titlebar .cx-title {
    font-weight: 300 !important;
  }
  .cx-powered-by, .cx-branding-icon {
    display: none !important;
  }
  .cxPS {
    display: none;
  }
  
  
  
  
  .cx-widget.cx-theme-dark {
    color: #FDFDFD;
    background-color: #33383D;
    scrollbar-face-color: #A3A8AE;
    scrollbar-track-color: #212529;
    scrollbar-arrow-color: #A3A8AE;
  }
  .cx-widget.cx-theme-dark .cx-theme {
    background-color: #33383D;
    color: #FDFDFD;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-overlay {
    background-color: #33383D;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-tone1 {
    fill: #73b842;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-tone2 {
    fill: #8C8C8C;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
  }
  .cx-widget.cx-theme-dark * {
    border-color: #b1b1b1;
  }
  .cx-widget.cx-theme-dark .cx-titlebar .cx-title {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark label {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark a {
    color: black;
  }
  .cx-widget.cx-theme-dark a:hover {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-icon-alert-circle {
    color: #F53131;
  }
  .cx-widget.cx-theme-dark .cx-footer, .cx-widget.cx-theme-dark .cx-branding {
    color: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-footer *, .cx-widget.cx-theme-dark .cx-branding * {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-form-control {
    background-color: #444A52;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-form-control.cx-error {
    border-color: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-form-control::placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control:-moz-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control::-moz-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control:-ms-input-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control::-webkit-input-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark input:focus, .cx-widget.cx-theme-dark textarea:focus, .cx-widget.cx-theme-dark .cx-btn:focus, .cx-widget.cx-theme-dark .cx-button-group button:focus, .cx-widget.cx-theme-dark .cx-form-control:not(.cx-error):focus {
    border-color: #75A8FF !important;
  }
  .cx-widget.cx-theme-dark input, .cx-widget.cx-theme-dark select, .cx-widget.cx-theme-dark textarea {
    background-color: #444A52;
    color: #FDFDFD;
    border-color: #758384;
  }
  .cx-widget.cx-theme-dark .cx-btn-default {
    background: none;
    border-color: #b1b1b1 !important;
    color: #FDFDFD;
    background-color: #1b1c1d;
  }
  .cx-widget.cx-theme-dark .cx-btn.cx-disabled {
    background: #CCCCCC;
  }
  .cx-widget.cx-theme-dark .cx-btn-primary {
    color: black;
    border-color: #73b842 !important;
    background: #73b842;
    background: -moz-linear-gradient(top,#73b842 0%,#73b842 100%);
    background: -webkit-linear-gradient(top,#73b842 0%,#73b842 100%);
    background: linear-gradient(to bottom,#73b842 0%,#73b842 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0);
  }
  .cx-widget.cx-theme-dark .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-dark .cx-btn-primary:focus {
    border-color: #DAE6FC !important;
  }
  .cx-widget.cx-theme-dark .cx-ac-dropdown-menu {
    background: #33383D;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-ac-suggestion:hover, .cx-widget.cx-theme-dark .cx-ac-suggestion.cx-active {
    background-color: #75A8FF;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark ::-webkit-scrollbar-thumb {
    background: #A3A8AE;
  }
  .cx-widget.cx-theme-dark .cx-smokescreen {
    background-color: #33383D;
    opacity: 0.7;
  }
  .cx-widget.cx-theme-dark .cx-dialog-container .cx-dialog {
    background-color: #33383D;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-theme-background {
    background-color: #33383D;
  }
  .cx-widget.cx-theme-dark .cx-theme-border {
    border: 1px solid #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-theme-text {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #73b842;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #4AC764;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #F53131;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
  }
  .cx-widget.cx-theme-dark .cx-warning {
    color: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-countdown {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-countdown .cx-icon svg * {
    fill: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-message.cx-them .cx-bubble .cx-name, .cx-widget.cx-theme-dark .cx-message.cx-them .cx-bubble .cx-message-text, .cx-widget.cx-theme-dark .cx-message.cx-them .cx-bubble .cx-time {
    color: black !important;
  }
  .cx-widget.cx-theme-dark .cx-message.cx-them .cx-bubble .cx-time {
    opacity: 0.9;
  }
  `,
  'canadapost-prepaid': `.cx-widget.cx-theme-light {
    color: #444A52;
    background-color: #FDFDFD;
    scrollbar-face-color: #CCCCCC;
}
.cx-widget.cx-theme-light .cx-theme {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-overlay {
    background-color: #FDFDFD;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone1 {
    fill: #8C8C8C !important;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone2 {
    fill: #8C8C8C;
}
.cx-widget.cx-theme-light .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
}
.cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-name, .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-message-text, .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-time {
    color: white !important;
    opacity: 0.9;
}
.cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble .cx-time {
    opacity: 0.7 !important;
}
.cx-widget.cx-theme-light .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
}
.cx-widget.cx-theme-light * {
    border-color: #DAE1E8;
}
.cx-widget.cx-theme-light .cx-titlebar .cx-title {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #8795A6;
}
.cx-widget.cx-theme-light .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
}
.cx-widget.cx-theme-light .cx-input-group-btn .cx-svg-icon-tone1 {
    fill: #FDFDFD;
}
.cx-widget.cx-theme-light label {
    color: #444A52;
}
.cx-widget.cx-theme-light a {
    color: white;
}
.cx-widget.cx-theme-light a:hover {
    color: white;
}
.cx-widget.cx-theme-light .cx-icon-alert-circle {
    color: #F53131;
}
.cx-widget.cx-theme-light .cx-footer, .cx-widget.cx-theme-light .cx-branding {
    color: #66759B;
}
.cx-widget.cx-theme-light .cx-footer *, .cx-widget.cx-theme-light .cx-branding * {
    fill: #66759B;
}
.cx-widget.cx-theme-light .cx-form-control.cx-error {
    border-color: #F53131;
}
.cx-widget.cx-theme-light .cx-form-control::placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control:-moz-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control::-moz-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control:-ms-input-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light .cx-form-control::-webkit-input-placeholder {
    color: #6F7491;
}
.cx-widget.cx-theme-light input:focus, .cx-widget.cx-theme-light textarea:focus, .cx-widget.cx-theme-light .cx-btn:focus, .cx-widget.cx-theme-light .cx-button-group button:focus, .cx-widget.cx-theme-light .cx-form-control:not(.cx-error):focus {
    border-color: #5791FD !important;
}
.cx-widget.cx-theme-light input[type="text"], .cx-widget.cx-theme-light input[type="email"], .cx-widget.cx-theme-light input[type="tel"], .cx-widget.cx-theme-light select, .cx-widget.cx-theme-light textarea {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #94948A;
}
.cx-widget.cx-theme-light .cx-btn-default {
    background: none;
    border-color: #DAE1E8 !important;
    color: #FDFDFD;
    background-color: #212121 !important;
}
.cx-widget.cx-theme-light .cx-btn.cx-disabled {
    background: #CCCCCC;
}
.cx-widget.cx-theme-light .cx-btn-primary {
    color: white;
    border-color: transparent !important;
    background: #4372b8 !important;
    background: -moz-linear-gradient(top,#4372b8 0%,#4372b8 100%) !important;
    background: -webkit-linear-gradient(top,#4372b8 0%,#4372b8 100%) !important;
    background: linear-gradient(to bottom,#4372b8 0%,#4372b8 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0) !important;
    background-color: #4372b8 !important;
}
.cx-widget.cx-theme-light .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-button-group .cx-btn-default:focus, .cx-widget.cx-theme-light .cx-btn-default:focus {
    border-color: #000 !important;
}
.cx-widget.cx-theme-light .cx-ac-dropdown-menu {
    background: #FDFDFD;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-ac-suggestion:hover, .cx-widget.cx-theme-light .cx-ac-suggestion.cx-active {
    background-color: #5791FD;
    color: #FDFDFD;
}
.cx-widget.cx-theme-light ::-webkit-scrollbar-thumb {
    background: #939399;
}
.cx-widget.cx-theme-light .cx-smokescreen {
    background-color: #FDFDFD;
    opacity: 0.7;
}
.cx-widget.cx-theme-light .cx-dialog-container .cx-dialog {
    background-color: #FDFDFD;
    border-color: #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-theme-background {
    background-color: #FDFDFD;
}
.cx-widget.cx-theme-light .cx-theme-border {
    border: 1px solid #DAE1E8 !important;
}
.cx-widget.cx-theme-light .cx-theme-text {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #4372b8;
}
.cx-widget.cx-theme-light .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #34A84F;
}
.cx-widget.cx-theme-light .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #4372b8 !important;
}
.cx-widget.cx-theme-light .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
}
.cx-widget.cx-theme-light .cx-warning {
    color: #F53131 !important;
}
.cx-widget.cx-theme-light .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
}
.cx-widget.cx-theme-light .cx-countdown {
    color: #444A52;
}
.cx-widget.cx-theme-light .cx-countdown .cx-icon svg * {
    fill: #444A52;
}
.cx-loading-screen .cx-loading-icon svg g g, .cx-loading-container .cx-loading-icon svg g g {
    stroke: #2B74FF !important;
}
.cx-loading-screen .cx-loading-icon svg g:first-child g, .cx-loading-container .cx-loading-icon svg g:first-child g {
    stroke: #72799D !important;
}
.cx-message.cx-them .cx-bubble {
    background-color: #4372b8 !important;
}
.cx-message.cx-them .cx-bubble-arrow svg * {
    fill: #4372b8 !important;
}
.cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-agent .cx-time {
    opacity: 0.8 !important;
}
.cx-common-container .cx-titlebar .cx-title {
    font-weight: 300 !important;
}
.cx-powered-by, .cx-branding-icon {
    display: none !important;
}
.cxPS {
    display: none;
}






.cx-widget.cx-theme-dark {
    color: #FDFDFD;
    background-color: #33383D;
    scrollbar-face-color: #A3A8AE;
    scrollbar-track-color: #212529;
    scrollbar-arrow-color: #A3A8AE;
}
.cx-widget.cx-theme-dark .cx-theme {
    background-color: #33383D;
    color: #FDFDFD;
    border-color: #b1b1b1 !important;
}
.cx-widget.cx-theme-dark .cx-overlay {
    background-color: #33383D;
}
.cx-widget.cx-theme-dark .cx-svg-icon-tone1 {
    fill: #ffffff;
}
.cx-widget.cx-theme-dark .cx-svg-icon-tone2 {
    fill: #8C8C8C;
}
.cx-widget.cx-theme-dark .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
}
.cx-widget.cx-theme-dark .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
}
.cx-widget.cx-theme-dark * {
    border-color: #b1b1b1;
}
.cx-widget.cx-theme-dark .cx-titlebar .cx-title {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #98A7B8;
}
.cx-widget.cx-theme-dark .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
}
.cx-widget.cx-theme-dark label {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark a {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark a:hover {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-icon-alert-circle {
    color: #F53131;
}
.cx-widget.cx-theme-dark .cx-footer, .cx-widget.cx-theme-dark .cx-branding {
    color: #98A7B8;
}
.cx-widget.cx-theme-dark .cx-footer *, .cx-widget.cx-theme-dark .cx-branding * {
    fill: #98A7B8;
}
.cx-widget.cx-theme-dark .cx-form-control {
    background-color: #444A52;
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-form-control.cx-error {
    border-color: #F53131 !important;
}
.cx-widget.cx-theme-dark .cx-form-control::placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark .cx-form-control:-moz-placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark .cx-form-control::-moz-placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark .cx-form-control:-ms-input-placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark .cx-form-control::-webkit-input-placeholder {
    color: #C5CCD6;
}
.cx-widget.cx-theme-dark input:focus, .cx-widget.cx-theme-dark textarea:focus, .cx-widget.cx-theme-dark .cx-btn:focus, .cx-widget.cx-theme-dark .cx-button-group button:focus, .cx-widget.cx-theme-dark .cx-form-control:not(.cx-error):focus {
    border-color: #75A8FF !important;
}
.cx-widget.cx-theme-dark input, .cx-widget.cx-theme-dark select, .cx-widget.cx-theme-dark textarea {
    background-color: #444A52;
    color: #FDFDFD;
    border-color: #758384;
}
.cx-widget.cx-theme-dark .cx-btn-default {
    background: none;
    border-color: #b1b1b1 !important;
    color: #FDFDFD;
    background-color: #212121;
}
.cx-widget.cx-theme-dark .cx-btn.cx-disabled {
    background: #CCCCCC;
}
.cx-widget.cx-theme-dark .cx-btn-primary {
    color: black;
    border-color: #ffffff !important;
    background: #ffffff;
    background: -moz-linear-gradient(top,#ffffff 0%,#ffffff 100%);
    background: -webkit-linear-gradient(top,#ffffff 0%,#ffffff 100%);
    background: linear-gradient(to bottom,#ffffff 0%,#ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0);
}
.cx-widget.cx-theme-dark .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-dark .cx-btn-primary:focus {
    border-color: #DAE6FC !important;
}
.cx-widget.cx-theme-dark .cx-ac-dropdown-menu {
    background: #33383D;
    border-color: #b1b1b1 !important;
}
.cx-widget.cx-theme-dark .cx-ac-suggestion:hover, .cx-widget.cx-theme-dark .cx-ac-suggestion.cx-active {
    background-color: #75A8FF;
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark ::-webkit-scrollbar-thumb {
    background: #A3A8AE;
}
.cx-widget.cx-theme-dark .cx-smokescreen {
    background-color: #33383D;
    opacity: 0.7;
}
.cx-widget.cx-theme-dark .cx-dialog-container .cx-dialog {
    background-color: #33383D;
    border-color: #b1b1b1 !important;
}
.cx-widget.cx-theme-dark .cx-theme-background {
    background-color: #33383D;
}
.cx-widget.cx-theme-dark .cx-theme-border {
    border: 1px solid #b1b1b1 !important;
}
.cx-widget.cx-theme-dark .cx-theme-text {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #ffffff;
}
.cx-widget.cx-theme-dark .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #4AC764;
}
.cx-widget.cx-theme-dark .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #F53131;
}
.cx-widget.cx-theme-dark .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
}
.cx-widget.cx-theme-dark .cx-warning {
    color: #F53131 !important;
}
.cx-widget.cx-theme-dark .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
}
.cx-widget.cx-theme-dark .cx-countdown {
    color: #FDFDFD;
}
.cx-widget.cx-theme-dark .cx-countdown .cx-icon svg * {
    fill: #FDFDFD;
}
`,
  gameplayshop: `.cx-widget.cx-theme-light {
    color: #444A52;
    background-color: #FDFDFD;
    scrollbar-face-color: #CCCCCC;
  }
  .cx-widget.cx-theme-light .cx-time {
    color: white;
  }
  .cx-widget.cx-theme-light .cx-theme {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-overlay {
    background-color: #FDFDFD;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-tone1 {
    fill: #e60000 !important;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-tone2 {
    fill: #8C8C8C;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
  }
  .cx-widget.cx-theme-light .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
  }
  .cx-widget.cx-theme-light * {
    border-color: #DAE1E8;
  }
  .cx-widget.cx-theme-light .cx-titlebar .cx-title {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #8795A6;
  }
  .cx-widget.cx-theme-light .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-light .cx-input-group-btn .cx-svg-icon-tone1 {
    fill: #FDFDFD;
  }
  .cx-widget.cx-theme-light label {
    color: #444A52;
  }
  .cx-widget.cx-theme-light a {
    color: white;
  }
  .cx-widget.cx-theme-light a:hover {
    color: white;
  }
  .cx-widget.cx-theme-light .cx-icon-alert-circle {
    color: #F53131;
  }
  .cx-widget.cx-theme-light .cx-footer, .cx-widget.cx-theme-light .cx-branding {
    color: #66759B;
  }
  .cx-widget.cx-theme-light .cx-footer *, .cx-widget.cx-theme-light .cx-branding * {
    fill: #66759B;
  }
  .cx-widget.cx-theme-light .cx-form-control.cx-error {
    border-color: #F53131;
  }
  .cx-widget.cx-theme-light .cx-form-control::placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control:-moz-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control::-moz-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control:-ms-input-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light .cx-form-control::-webkit-input-placeholder {
    color: #6F7491;
  }
  .cx-widget.cx-theme-light input:focus, .cx-widget.cx-theme-light textarea:focus, .cx-widget.cx-theme-light .cx-btn:focus, .cx-widget.cx-theme-light .cx-button-group button:focus, .cx-widget.cx-theme-light .cx-form-control:not(.cx-error):focus {
    border-color: #5791FD !important;
  }
  .cx-widget.cx-theme-light input[type="text"], .cx-widget.cx-theme-light input[type="email"], .cx-widget.cx-theme-light input[type="tel"], .cx-widget.cx-theme-light select, .cx-widget.cx-theme-light textarea {
    background-color: #FDFDFD;
    color: #444A52;
    border-color: #94948A;
  }
  .cx-widget.cx-theme-light .cx-btn-default {
    background: none;
    border-color: #DAE1E8 !important;
    color: #FDFDFD;
    background-color: #1b1c1d !important;
  }
  .cx-widget.cx-theme-light .cx-btn.cx-disabled {
    background: #CCCCCC;
  }
  .cx-widget.cx-theme-light .cx-btn-primary {
    color: white;
    border-color: transparent !important;
    background: #e60000 !important;
    background: -moz-linear-gradient(top,#e60000 0%,#e60000 100%) !important;
    background: -webkit-linear-gradient(top,#e60000 0%,#e60000 100%) !important;
    background: linear-gradient(to bottom,#e60000 0%,#e60000 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0) !important;
    background-color: #e60000 !important;
  }
  .cx-widget.cx-theme-light .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-btn-primary:focus, .cx-widget.cx-theme-light .cx-button-group .cx-btn-default:focus, .cx-widget.cx-theme-light .cx-btn-default:focus {
    border-color: #000 !important;
  }
  .cx-widget.cx-theme-light .cx-ac-dropdown-menu {
    background: #FDFDFD;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-ac-suggestion:hover, .cx-widget.cx-theme-light .cx-ac-suggestion.cx-active {
    background-color: #5791FD;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-light ::-webkit-scrollbar-thumb {
    background: #939399;
  }
  .cx-widget.cx-theme-light .cx-smokescreen {
    background-color: #FDFDFD;
    opacity: 0.7;
  }
  .cx-widget.cx-theme-light .cx-dialog-container .cx-dialog {
    background-color: #FDFDFD;
    border-color: #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-background {
    background-color: #FDFDFD;
  }
  .cx-widget.cx-theme-light .cx-theme-border {
    border: 1px solid #DAE1E8 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-text {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #e60000;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #34A84F;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #e60000 !important;
  }
  .cx-widget.cx-theme-light .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
  }
  .cx-widget.cx-theme-light .cx-warning {
    color: #F53131 !important;
  }
  .cx-widget.cx-theme-light .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
  }
  .cx-widget.cx-theme-light .cx-countdown {
    color: #444A52;
  }
  .cx-widget.cx-theme-light .cx-countdown .cx-icon svg * {
    fill: #444A52;
  }
  .cx-loading-screen .cx-loading-icon svg g g, .cx-loading-container .cx-loading-icon svg g g {
    stroke: #2B74FF !important;
  }
  .cx-loading-screen .cx-loading-icon svg g:first-child g, .cx-loading-container .cx-loading-icon svg g:first-child g {
    stroke: #72799D !important;
  }
  .cx-message.cx-them .cx-bubble {
    color: white !important;
    background-color: #e60000 !important;
  }
  .cx-message.cx-them .cx-bubble-arrow svg * {
    color: white !important;
    fill: #e60000 !important;
  }
  .cx-webchat .cx-transcript .cx-message-group > .cx-message.cx-participant.cx-agent .cx-time {
    color: white !important;
    opacity: 0.8 !important;
  }
  .cx-message.cx-them .cx-bubble .cx-name {
    color: white !important;
    font-weight: bolder !important;
  }
  .cx-common-container .cx-titlebar .cx-title {
    font-weight: 300 !important;
  }
  .cx-powered-by, .cx-branding-icon {
    display: none !important;
  }
  .cxPS {
    display: none;
  }
  
  
  
  
  
  .cx-widget.cx-theme-dark {
    color: #FDFDFD;
    background-color: #33383D;
    scrollbar-face-color: #A3A8AE;
    scrollbar-track-color: #212529;
    scrollbar-arrow-color: #A3A8AE;
  }
  .cx-widget.cx-theme-dark .cx-theme {
    background-color: #33383D;
    color: #FDFDFD;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-overlay {
    background-color: #33383D;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-tone1 {
    fill: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-tone2 {
    fill: #8C8C8C;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-shadow1 {
    fill: #000000;
    opacity: 0.2;
  }
  .cx-widget.cx-theme-dark .cx-svg-icon-shadow2 {
    fill: #000000;
    opacity: 0.1;
  }
  .cx-widget.cx-theme-dark * {
    border-color: #b1b1b1;
  }
  .cx-widget.cx-theme-dark .cx-titlebar .cx-title {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark label {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark a {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark a:hover {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-icon-alert-circle {
    color: #F53131;
  }
  .cx-widget.cx-theme-dark .cx-footer, .cx-widget.cx-theme-dark .cx-branding {
    color: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-footer *, .cx-widget.cx-theme-dark .cx-branding * {
    fill: #98A7B8;
  }
  .cx-widget.cx-theme-dark .cx-form-control {
    background-color: #444A52;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-form-control.cx-error {
    border-color: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-form-control::placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control:-moz-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control::-moz-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control:-ms-input-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark .cx-form-control::-webkit-input-placeholder {
    color: #C5CCD6;
  }
  .cx-widget.cx-theme-dark input:focus, .cx-widget.cx-theme-dark textarea:focus, .cx-widget.cx-theme-dark .cx-btn:focus, .cx-widget.cx-theme-dark .cx-button-group button:focus, .cx-widget.cx-theme-dark .cx-form-control:not(.cx-error):focus {
    border-color: #75A8FF !important;
  }
  .cx-widget.cx-theme-dark input, .cx-widget.cx-theme-dark select, .cx-widget.cx-theme-dark textarea {
    background-color: #444A52;
    color: #FDFDFD;
    border-color: #758384;
  }
  .cx-widget.cx-theme-dark .cx-btn-default {
    background: none;
    border-color: #b1b1b1 !important;
    color: #FDFDFD;
    background-color: #1b1c1d;
  }
  .cx-widget.cx-theme-dark .cx-btn.cx-disabled {
    background: #CCCCCC;
  }
  .cx-widget.cx-theme-dark .cx-btn-primary {
    color: white;
    border-color: #e60000 !important;
    background: #e60000;
    background: -moz-linear-gradient(top,#e60000 0%,#e60000 100%);
    background: -webkit-linear-gradient(top,#e60000 0%,#e60000 100%);
    background: linear-gradient(to bottom,#e60000 0%,#e60000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0);
  }
  .cx-widget.cx-theme-dark .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-dark .cx-btn-primary:focus {
    border-color: #DAE6FC !important;
  }
  .cx-widget.cx-theme-dark .cx-ac-dropdown-menu {
    background: #33383D;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-ac-suggestion:hover, .cx-widget.cx-theme-dark .cx-ac-suggestion.cx-active {
    background-color: #75A8FF;
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark ::-webkit-scrollbar-thumb {
    background: #A3A8AE;
  }
  .cx-widget.cx-theme-dark .cx-smokescreen {
    background-color: #33383D;
    opacity: 0.7;
  }
  .cx-widget.cx-theme-dark .cx-dialog-container .cx-dialog {
    background-color: #33383D;
    border-color: #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-theme-background {
    background-color: #33383D;
  }
  .cx-widget.cx-theme-dark .cx-theme-border {
    border: 1px solid #b1b1b1 !important;
  }
  .cx-widget.cx-theme-dark .cx-theme-text {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #e60000;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #4AC764;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #F53131;
  }
  .cx-widget.cx-theme-dark .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #D47F2F;
  }
  .cx-widget.cx-theme-dark .cx-warning {
    color: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-warning .cx-icon svg * {
    fill: #F53131 !important;
  }
  .cx-widget.cx-theme-dark .cx-countdown {
    color: #FDFDFD;
  }
  .cx-widget.cx-theme-dark .cx-countdown .cx-icon svg * {
    fill: #FDFDFD;
  }
  `,
};

export default dict;
