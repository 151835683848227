import React from 'react';
import { t } from 'react-switch-lang';

function PageTitle() {
  return (
    <div
      id="page-title"
      role="region"
      aria-live="polite"
      className="visually-hidden"
    >
      Help Center
    </div>
  );
}

function isGamePlayShop() {
  if (window.location.href.toLowerCase().includes('gameplayshop')) {
    return 'Help Center';
  }
  if (window.location.href.toLowerCase().includes('/fr/')) {
    return t('Help_Centre_Name', null, 'fr');
  }
  return t('Help_Centre_Name', null, 'en');
}

export const DocumentTitle = (title) => {
  document.title = `${title} ${isGamePlayShop()}`;
  const e = document.getElementById('page-title');
  if (e) e.textContent = title;
};

export default PageTitle;
