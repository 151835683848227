import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import './contactusform.scss';
import './ContactUsZenwallet.scss';
import { t, translate } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  SubmitContactUs,
  hideContactError, validateEmail, validateDescriptionBody, showErrorFromList,
} from '../../../helperFunctions/SubmitContactUs';
import Passport from '../../../images/zenwallet/CashPassport.png';
import { event as ampEvent, event, logAmpEvent } from '../../../helperFunctions/Amplitude';
import { setScreenReaderAlert } from '../../../helperFunctions/Accessibility';
import RadioBuilder from './RadioBuilder';
import { performSearch } from '../../../components/algolia/AlgoliaUtils';
import RelevantFAQ from './RelevantFAQ';

function ContactUsZenwallet({ widgetMode }) {
  const { brand, lang } = useSelector((state) => state);

  const emailRef = useRef();
  const bodyref = useRef();
  const [appId, setAppId] = useState('');
  const [category, setCategory] = useState('');
  const cardType = 'Canada Post Cash Passport™ Prepaid Mastercard';
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [disable, setDisable] = React.useState(false);

  const [webActive, setWebActive] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);

  const [descriptionValidateOnChange, setDescriptionValidateOnChange] = useState(false);
  const [shouldValidateEmailOnChange, setEmailValidateOnChange] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedArticleAlertRead, setSuggestedArticleAlertRead] = useState(false);

  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  useEffect(() => {
    if (widgetMode) {
      document.getElementById('desktop-contact-us').style.padding = '0px';
    }
    logAmpEvent(event.USER_VIEWED_CONTACT_SCREEN);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (appId !== '') {
      logAmpEvent(event.USER_SELECTED_APP_USED, { App: appId });
    }
  }, [appId]);

  useEffect(() => {
    if (category !== '') {
      logAmpEvent(event.USER_SELECTED_HELP_CATEGORY, { 'Help Category': category });
    }
  }, [category]);

  useEffect(() => {
    if (cardType !== '') {
      logAmpEvent(event.USER_SELECT_CONTACT_CARD_TYPE, { 'Card Type': cardType });
    }
  }, [cardType]);

  useEffect(() => {
    async function updateSearchResults() {
      const results = await performSearch(searchTerm, brand, lang);
      if (results.length > 0) {
        setSuggestedArticleAlertRead(true);
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_Suggested_Article', null, lang));
      } else if (suggestedArticleAlertRead) {
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_No_Suggested_Article', null, lang));
      }
      setSearchResults(results);
    }
    const timer = setTimeout(updateSearchResults, 700);
    return () => { clearTimeout(timer); };
  }, [searchTerm, lang]);

  function handleSecondaryClick(target) {
    hideContactError('category_error_message');
    switch (target) {
      case 'r1':
        setCategory('Card Usage');
        break;
      case 'r2':
        setCategory('Loading Issues');
        break;
      case 'r3':
        setCategory('Other');
        break;
      default:
        break;
    }
  }

  function handleWebMobileClick(target) {
    hideContactError('app_error_message');
    if (target === 'web') {
      setAppId('Zenwallet.ca website');
      setMobileActive(false);
      setWebActive(true);
    }
    if (target === 'mobile') {
      setAppId('Zenwallet mobile app');
      setMobileActive(true);
      setWebActive(false);
    }
  }

  function cardUsageMessage() {
    setShow(false);
    document.getElementById('desktop-contact-us').style.visibility = 'hidden';
    document.getElementById('card-usage-message').style.visibility = 'visible';
    document.getElementById('card-usage-message').style.display = 'block';
    const focus = document.getElementById('card-usage-message');
    focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  function sendAPI() {
    const data = {
      BrandID: appId,
      CustomerEmail: emailRef.current.value,
      Language: lang,
      Category: category,
      CardType: cardType,
      Body: bodyref.current.value,
    };

    const sending = document.getElementById('submit-button');
    sending.innerHTML = t('ContactForm_Submitting');
    setDisable(true);

    SubmitContactUs(data).then(
      () => {
        setShow(false);
        document.getElementById('desktop-contact-us').style.visibility = 'hidden';
        document.getElementById('successMessage').style.visibility = 'visible';
        document.getElementById('successMessage').style.display = 'block';
        const focus = document.getElementById('successMessage');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      () => {
        setError(t('Error_Something_Went_Wrong'));
        setShow(true);
        sending.innerHTML = t('ContactForm_Submit_button');
        setDisable(false);
        const focus = document.getElementById('error_message');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    );
  }

  function handleSubmit(e) {
    setDescriptionValidateOnChange(true);
    setEmailValidateOnChange(true);
    const errorList = [];
    e.preventDefault();
    logAmpEvent(event.USER_CLICKED_SUBMIT);

    const emailError = validateEmail(emailRef);
    if (emailError !== undefined) {
      document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
      errorList.push(emailError);
    }

    if (appId === '') {
      errorList.push('app_error_message');
    } else {
      hideContactError('app_error_message');
    }

    if (category === '') {
      errorList.push('category_error_message');
    } else {
      hideContactError('category_error_message');
    }

    if (cardType === '') {
      errorList.push('cardtype_error_message');
    } else {
      hideContactError('cardtype_error_message');
    }

    if (validateDescriptionBody(bodyref) !== undefined) {
      document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
      errorList.push(validateDescriptionBody(bodyref));
    }

    showErrorFromList(errorList, widgetMode);

    if (errorList.length === 0) {
      // check if user selected card usage
      if (category === 'Card Usage') {
        cardUsageMessage();
      } else {
        sendAPI();
        setScreenReaderAlert(t('ScreenReader_ContactForm_Success'));
      }
    } else {
      logAmpEvent(ampEvent.USER_ERROR_CONTACT, { 'Error Type': errorList });
      setScreenReaderAlert(t('ScreenReader_ContactForm_Error'));
    }
  }

  const handleTextareaChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      {show && (
        <Alert
          variant="danger"
          id="error_message"
          className="mt-3"
          onClose={() => {
            setShow(false);
            setError('');
          }}
          dismissible
        >
          <p style={{ marginBottom: 0 }}>{error}</p>
        </Alert>
      )}
      <div id="successMessage">
        <h1>{t('ContactForm_Submitted')}</h1>
        <Link to={(widgetMode) ? `/widget/${brand}/${lang}${isMobile}` : `/${brand}/${lang}/categories`}>
          <Button className={`error-go-home-btn ${brand}-button`}>{t('ReturnHome_Btn')}</Button>
        </Link>
      </div>
      <div id="card-usage-message">
        <h1 dangerouslySetInnerHTML={{ __html: t('Zenwallet_Card_Usage_Message') }} />
        <Link to={(widgetMode) ? `/widget/${brand}/${lang}${isMobile}` : `/${brand}/${lang}/categories`}>
          <Button className={`error-go-home-btn ${brand}-button`}>{t('ReturnHome_Btn')}</Button>
        </Link>
      </div>
      <div id="desktop-contact-us">
        <div id="skip-link-content">
          <h1>{t('General_Contact_Us')}</h1>
          <p className="contact-label-suggestion">{t('ContactForm_Fields_Required')}</p>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Email_"
              required
            >
              <fieldset>

                <Form.Label as="legend" id="aria-email" className="form-labels">
                  {t('ContactForm_General_Email_Label')}
                </Form.Label>

                <Form.Text className="muted email-help">
                  {t('ContactForm_General_Email_Help')}
                </Form.Text>
                <Form.Control
                  aria-labelledby="aria-email aria-email-warn"
                  type="text"
                  ref={emailRef}
                  className="email-attr"
                  onBlur={() => {
                    setEmailValidateOnChange(true);
                    const curError = validateEmail(emailRef);
                    if (curError !== undefined) {
                      document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else {
                      if (document.getElementsByClassName('email-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('email-attr')[0].classList.remove('is-invalid');
                      }
                      showErrorFromList(validateEmail(emailRef));
                    }
                  }}
                  onChange={() => {
                    if (shouldValidateEmailOnChange) {
                      const curError = validateEmail(emailRef);
                      if (curError !== undefined) {
                        document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else if (document.getElementsByClassName('email-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('email-attr')[0].classList.remove('is-invalid');
                      }
                    }
                  }}
                />
                <Form.Text
                  id="email_invalid"
                  className="error-handling-messages"
                >
                  {t('ContactForm_General_Email_Validation')}
                </Form.Text>
                <Form.Text
                  id="email_empty_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Email_Error')}
                </Form.Text>
                <Form.Text
                  id="email_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Email_Character_Length_Error')}
                </Form.Text>
              </fieldset>
            </Form.Group>

            <Form.Group
              className="form-inline"
              controlId="ContactForm_AppID_"
              required
            >
              <fieldset>
                <Form.Label as="legend" id="app_label" className="form-labels">
                  {t('ContactForm_General_AppID_Label')}
                </Form.Label>

                <div className="web-mobile-btn zenwallet-web-mobile">
                  <Form.Check>
                    <Button
                      className={`clickable-btn ${
                        mobileActive ? 'active-btn' : 'inactive-btn'
                      }`}
                      id="contact-mobile-btn"
                      name="ContactForm_AppID_"
                      onClick={() => {
                        handleWebMobileClick('mobile');
                      }}
                    >
                      <i className="fal fa-mobile-alt btn-icon" />
                      {t('ContactForm_AppID_Mobile')}
                    </Button>
                  </Form.Check>

                  <Form.Check>
                    <Button
                      className={`clickable-btn ${
                        webActive ? 'active-btn' : 'inactive-btn'
                      }`}
                      id="contact-web-btn"
                      name="ContactForm_AppID_"
                      onClick={() => {
                        handleWebMobileClick('web');
                      }}
                    >
                      <i className="fal fa-window btn-icon" />
                      {t('ContactForm_AppID_Website')}
                    </Button>
                  </Form.Check>
                </div>
                <Form.Text
                  id="app_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_App_Validation_Error')}
                </Form.Text>
              </fieldset>
            </Form.Group>

            {/* What do you need help with? */}
            <fieldset className="zenwallet-radio">
              <Form.Label as="legend" id="category_label" className="form-labels">
                {t('ContactForm_General_Category_Label')}
              </Form.Label>
              <RadioBuilder
                content={(
                  <>
                    {' '}
                    {t('ContactForm_Category_Zenwallet_CardServices')}
                    <div style={{ fontSize: 'smaller' }}>
                      {t('ContactForm_Category_Zenwallet_CardServices_Deets')}
                    </div>
                  </>
              )}
                clickHandler={handleSecondaryClick}
                value="r1"
                group="radio"
              />
              <RadioBuilder content={t('ContactForm_Category_Zenwallet_LoadIssue')} clickHandler={handleSecondaryClick} value="r2" group="radio" />
              <RadioBuilder content={t('ContactForm_Category_General_Other')} clickHandler={handleSecondaryClick} value="r3" group="radio" />

              <Form.Text
                id="category_error_message"
                className="error-handling-messages"
              >
                {t('ContactForm_Category_Validation_Error')}
              </Form.Text>
              <Form.Text
                id="category_card_error_message"
                className="error-handling-messages"
              >
                {t('ContactForm_Category_Validation_Error')}
              </Form.Text>
            </fieldset>

            {/* Which card do you need help with? */}
            <fieldset className="zenwallet-radio">

              <Form.Label
                as="legend"
                id="cardtype_label"
                className="form-labels"
              >
                {t('ContactForm_General_CardType_Label')}
              </Form.Label>

              <RadioBuilder
                value="c1"
                content={(
                  <>
                    <img
                      className="card_images"
                      src={Passport}
                      alt={t('ContactForm_ProductType_Zenwallet_CashPassport')}
                    />
                    {t('ContactForm_ProductType_Zenwallet_CashPassport')}
                  </>
                  )}
                group="card"
                selected
              />

              <Form.Text
                id="cardtype_error_message"
                className="error-handling-messages"
              >
                {t('ContactForm_Card_Type_Validation_Error')}
              </Form.Text>
            </fieldset>

            {/* Description textbox field */}
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Body_"
              required
            >
              <fieldset>
                <Form.Label as="legend" id="aria-desc" className="form-labels">
                  {t('ContactForm_General_Body_Label')}
                </Form.Label>

                <Form.Control
                // onClick={() => {logAmpEvent(event.USER_CLICK_CONTACT_TEXTBOX)}}
                  aria-labelledby="aria-desc"
                  as="textarea"
                  rows={8}
                  ref={bodyref}
                  className="description-attr"
                  onBlur={() => {
                    setDescriptionValidateOnChange(true);
                    const curError = validateDescriptionBody(bodyref);
                    if (curError !== undefined) {
                      document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else {
                      if (document.getElementsByClassName('description-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('description-attr')[0].classList.remove('is-invalid');
                      }
                      showErrorFromList(validateDescriptionBody(bodyref));
                    }
                  }}
                  onChange={(e) => {
                    handleTextareaChange(e);
                    if (descriptionValidateOnChange) {
                      const curError = validateDescriptionBody(bodyref);
                      if (curError !== undefined) {
                        document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else {
                        if (document.getElementsByClassName('description-attr')[0].classList.contains('is-invalid')) {
                          document.getElementsByClassName('description-attr')[0].classList.remove('is-invalid');
                        }
                        showErrorFromList(validateDescriptionBody(bodyref));
                      }
                    }
                  }}
                />
                <Form.Text
                  id="description_empty_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Body_Error')}
                </Form.Text>
                <Form.Text
                  id="description_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Body_Character_Length_Error')}
                </Form.Text>
              </fieldset>
            </Form.Group>
            <RelevantFAQ searchResults={searchResults} brand={brand} lang={lang} />

            <Button
              id="submit-button"
              className="zenwallet-button"
              type="submit"
              disabled={disable}
              size="lg"
              style={{ backgroundColor: '#2B4E7B' }}
            >
              {t('ContactForm_Submit_button')}
            </Button>
          </Form>
          <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
            On the test system, contact form emails will be sent to
            <strong> psidevstar@gmail.com</strong>
          </Alert>
        </div>
      </div>
    </>
  );
}

export default translate(ContactUsZenwallet);
