import React from 'react';

import { useSelector } from 'react-redux';
import { t } from 'react-switch-lang';
import ContactUsMaple from './ContactUsMaple';
import ContactUsTopmeup from './ContactUsTopmeup';
import ContactUsPaySimply from './ContactUsPaySimply';
import ContactUsZenwallet from './ContactUsZenwallet';
import ContactUsNowPrepay from './ContactUsNowPrepay';
import ContactusDirectPay from './ContactUsDirectPay';
import ContactUsGameplayshop from './ContactUsGameplayshop';
import { DocumentTitle } from '../../../helperFunctions/DocumentTitle';
import { brandTitleGet } from '../../../configurations/Brands';
import NotFound from '../NotFound';

function ContactSorter() {
  const { brand, lang } = useSelector((state) => state);
  DocumentTitle(
    t('PageTitle_Brand_Contact').replace('xBrand', brandTitleGet(brand, lang))
  );
  const contactList = {
    paysimply: <ContactUsPaySimply lang={lang} />,
    zenwallet: <ContactUsZenwallet lang={lang} />,
    topmeup: <ContactUsTopmeup lang={lang} />,
    nowprepay: <ContactUsNowPrepay lang={lang} />,
    directpay: <ContactusDirectPay lang={lang} />,
    'canadapost-prepaid': <ContactUsMaple lang={lang} />,
    gameplayshop: <ContactUsGameplayshop lang={lang} />,
    'canadapost-singleloadprepaid': <NotFound brand={brand} lang={lang} />,
  };

  return contactList[brand];
}

export default ContactSorter;
