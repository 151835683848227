import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { t, translate } from 'react-switch-lang';
// import * as Radix from '@radix-ui/react-radio-group';
import {
  SubmitContactUs,
  hideContactError, validateEmail, validateDescriptionBody, showErrorFromList,
} from '../../../helperFunctions/SubmitContactUs';
import './contactusform.scss';
import './ContactUsGameplayshop.scss';
import { event as ampEvent, event, logAmpEvent } from '../../../helperFunctions/Amplitude';
import { setScreenReaderAlert } from '../../../helperFunctions/Accessibility';
// import RadixItemBuilder from './RadixItemBuilder';
import RadioBuilder from './RadioBuilder';
import { performSearch } from '../../../components/algolia/AlgoliaUtils';
import RelevantFAQ from './RelevantFAQ';

function ContactUsGameplayshop({ widgetMode }) {
  const { brand, lang } = useSelector((state) => state);

  const emailRef = useRef();
  const bodyref = useRef();
  const [appId] = useState('Gameplayshop');
  const [category, setCategory] = useState('');
  const [paymentOption, setPaymentOption] = useState('');
  const [isPayment] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [disable, setDisable] = React.useState(false);

  const [descriptionValidateOnChange, setDescriptionValidateOnChange] = useState(false);
  const [shouldValidateEmailOnChange, setEmailValidateOnChange] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedArticleAlertRead, setSuggestedArticleAlertRead] = useState(false);

  useEffect(() => {
    if (widgetMode) {
      document.getElementById('desktop-contact-us').style.padding = '0px';
    }
    logAmpEvent(event.USER_VIEWED_CONTACT_SCREEN);
}, []); //eslint-disable-line

  useEffect(() => {
    if (appId !== '') {
      logAmpEvent(event.USER_SELECTED_APP_USED, { App: appId });
    }
  }, [appId]);

  useEffect(() => {
    if (category !== '') {
      logAmpEvent(event.USER_SELECTED_HELP_CATEGORY, { 'Help Category': category });
    }
  }, [category]);

  useEffect(() => {
    if (paymentOption !== '') {
      logAmpEvent(event.USER_SELECTED_PAYMENT_OPTION_USED, { 'Payment Option': paymentOption });
    }
  }, [paymentOption]);

  useEffect(() => {
    async function updateSearchResults() {
      const results = await performSearch(searchTerm, brand, lang);
      if (results.length > 0) {
        setSuggestedArticleAlertRead(true);
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_Suggested_Article', null, lang));
      } else if (suggestedArticleAlertRead) {
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_No_Suggested_Article', null, lang));
      }
      setSearchResults(results);
    }
    const timer = setTimeout(updateSearchResults, 700);
    return () => { clearTimeout(timer); };
  }, [searchTerm, lang]);

  function handleSecondaryClick(target) {
    hideContactError('category_error_message');
    switch (target) {
      case 'r1':
        setCategory('Undelivered Vouchers');
        setPaymentOption('');
        break;
      case 'r2':
        setCategory('Payment Issue');
        break;
      case 'r3':
        setCategory('Account Inquiry');
        setPaymentOption('');
        break;
      case 'r4':
        setCategory('Other');
        setPaymentOption('');
        break;
      default:
        break;
    }
  }
  function sendAPI() {
    let data = {};
    if (isPayment) {
      data = {
        BrandID: appId,
        CustomerEmail: emailRef.current.value,
        Language: 'English',
        Category: category,
        PaymentOption: paymentOption,
        Body: bodyref.current.value,
      };
    } else {
      data = {
        BrandID: appId,
        CustomerEmail: emailRef.current.value,
        Language: lang,
        Category: category,
        Body: bodyref.current.value,
      };
    }

    const sending = document.getElementById('submit-button');
    sending.innerHTML = t('ContactForm_Submitting');
    setDisable(true);

    SubmitContactUs(data).then(
      () => {
        setShow(false);
        document.getElementById('desktop-contact-us').style.visibility = 'hidden';
        document.getElementById('successMessage').style.visibility = 'visible';
        document.getElementById('successMessage').style.display = 'block';
        const focus = document.getElementById('successMessage');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      () => {
        setError(t('Error_Something_Went_Wrong'));
        setShow(true);
        sending.innerHTML = t('ContactForm_Submit_button');
        setDisable(false);
        const focus = document.getElementById('error_message');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    );
  }

  function handleSubmit(e) {
    setDescriptionValidateOnChange(true);
    setEmailValidateOnChange(true);

    const errorList = [];

    e.preventDefault();
    logAmpEvent(event.USER_CLICKED_SUBMIT);

    const emailError = validateEmail(emailRef);
    if (emailError !== undefined) {
      document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
      errorList.push(emailError);
    }

    if (appId === '') {
      errorList.push('app_error_message');
    } else {
      hideContactError('app_error_message');
    }
    if (category === '') {
      errorList.push('category_error_message');
    } else {
      hideContactError('category_error_message');
    }
    // if (isPayment) {
    //   if (paymentOption === '') {
    //     document.getElementById('payment-option').classList.add('is-invalid');
    //     errorList.push('paymentOption_error_message');
    //   } else {
    //     if (document.getElementById('payment-option').classList.contains('is-invalid')) {
    //       document.getElementById('payment-option').classList.remove('is-invalid');
    //     }
    //     hideContactError('paymentOption_error_message');
    //   }
    // }

    if (validateDescriptionBody(bodyref) !== undefined) {
      document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
      errorList.push(validateDescriptionBody(bodyref));
    }

    showErrorFromList(errorList, widgetMode);

    if (errorList.length === 0) {
      sendAPI();
      setScreenReaderAlert(t('ScreenReader_ContactForm_Success'));
    } else {
      logAmpEvent(ampEvent.USER_ERROR_CONTACT, { 'Error Type': errorList });
      setScreenReaderAlert(t('ScreenReader_ContactForm_Error'));
    }
  }

  const handleTextareaChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      {show && (
      <Alert
        variant="danger"
        id="error_message"
        className="mt-3"
        onClose={() => {
          setShow(false);
          setError('');
        }}
        dismissible
      >
        <p style={{ marginBottom: 0 }}>{error}</p>
      </Alert>
      )}
      <div id="successMessage">
        <h1>{t('ContactForm_Submitted')}</h1>
        <Link to={`/${brand}/${lang}/categories`}>
          <Button className={`error-go-home-btn ${brand}-button`}>{t('ReturnHome_Btn')}</Button>
        </Link>
      </div>
      <div id="desktop-contact-us">
        <div id="skip-link-content">
          <h1>{t('General_Contact_Us')}</h1>
          <p className="contact-label-suggestion">{t('ContactForm_Fields_Required')}</p>

          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Email_"
              required
            >
              <fieldset>
                <Form.Label id="aria-email" as="legend" className="form-labels">
                  {t('ContactForm_General_Email_Label')}
                </Form.Label>
                <Form.Text className="muted email-help">
                  {t('ContactForm_General_Email_Help')}
                </Form.Text>
                <Form.Control
                  type="text"
                  aria-labelledby="aria-email-warn aria-email"
                  ref={emailRef}
                  className="email-attr"
                  onBlur={() => {
                    setEmailValidateOnChange(true);
                    const curError = validateEmail(emailRef);
                    if (curError !== undefined) {
                      document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else {
                      if (document.getElementsByClassName('email-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('email-attr')[0].classList.remove('is-invalid');
                      }
                      showErrorFromList(validateEmail(emailRef));
                    }
                  }}
                  onChange={() => {
                    if (shouldValidateEmailOnChange) {
                      const curError = validateEmail(emailRef);
                      if (curError !== undefined) {
                        document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else if (document.getElementsByClassName('email-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('email-attr')[0].classList.remove('is-invalid');
                      }
                    }
                  }}
                />
                <Form.Text
                  id="email_empty_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Email_Error')}
                </Form.Text>
                <Form.Text
                  id="email_invalid"
                  className="error-handling-messages"
                >
                  {t('ContactForm_General_Email_Validation')}
                </Form.Text>
                <Form.Text
                  id="email_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Email_Character_Length_Error')}
                </Form.Text>
              </fieldset>
            </Form.Group>

            {/* What do you need help with? */}
            <fieldset className="gameplayshop-radio">
              <Form.Label as="legend" id="category_label" className="form-labels">
                {t('ContactForm_General_Category_Label')}
              </Form.Label>
              <RadioBuilder content={t('ContactForm_Category_Topmeup_MissingVoucher')} clickHandler={handleSecondaryClick} value="r1" />
              <RadioBuilder content={t('ContactForm_Category_Topmeup_PaymentIssue')} clickHandler={handleSecondaryClick} value="r2" />
              <RadioBuilder content={t('ContactForm_Category_Topmeup_AccountInquiry')} clickHandler={handleSecondaryClick} value="r3" />
              <RadioBuilder content={t('ContactForm_Category_General_Other')} clickHandler={handleSecondaryClick} value="r4" />
            </fieldset>

            <Form.Text
              id="category_error_message"
              className="error-handling-messages"
            >
              {t('ContactForm_Category_Validation_Error')}
            </Form.Text>

            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Body_"
              required
            >
              <fieldset>
                <Form.Label id="aria-desc" as="legend" className="form-labels">
                  {t('ContactForm_General_Body_Label')}
                </Form.Label>

                <Form.Control
                    // onClick={() => {logAmpEvent(event.USER_CLICK_CONTACT_TEXTBOX)}}
                  aria-labelledby="aria-desc"
                  as="textarea"
                  rows={8}
                  ref={bodyref}
                  className="description-attr"
                  onBlur={() => {
                    setDescriptionValidateOnChange(true);
                    const curError = validateDescriptionBody(bodyref);
                    if (curError !== undefined) {
                      document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else {
                      if (document.getElementsByClassName('description-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('description-attr')[0].classList.remove('is-invalid');
                      }
                      showErrorFromList(validateDescriptionBody(bodyref));
                    }
                  }}
                  onChange={(e) => {
                    handleTextareaChange(e);
                    if (descriptionValidateOnChange) {
                      const curError = validateDescriptionBody(bodyref);
                      if (curError !== undefined) {
                        document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else {
                        if (document.getElementsByClassName('description-attr')[0].classList.contains('is-invalid')) {
                          document.getElementsByClassName('description-attr')[0].classList.remove('is-invalid');
                        }
                        showErrorFromList(validateDescriptionBody(bodyref));
                      }
                    }
                  }}
                />
                <Form.Text id="description_empty_error_message" className="error-handling-messages">
                  {t('ContactForm_Body_Error')}
                </Form.Text>
                <Form.Text id="description_error_message" className="error-handling-messages">
                  {t('ContactForm_Body_Character_Length_Error')}
                </Form.Text>
              </fieldset>
            </Form.Group>
            <RelevantFAQ searchResults={searchResults} brand={brand} lang={lang} />

            <Button
              id="submit-button"
              className="gameplayshop-submit-button"
              type="submit"
              disabled={disable}
              size="lg"
              style={{ backgroundColor: '#2B4E7B' }}
            >
              {t('ContactForm_Submit_button')}
            </Button>
          </Form>
          <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
            On the test system, contact form emails will be sent to
            <strong> psidevstar@gmail.com</strong>
          </Alert>
        </div>
      </div>
    </>
  );
}

export default translate(ContactUsGameplayshop);
