import React from 'react';
import './WidgetTopBar.scss';
import { Link, useHistory } from 'react-router-dom';
import { t, translate } from 'react-switch-lang';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import AlgoliaSearch from './widgetHelper/AlgoliaSearch';
import { hideWidget } from './widgetHelper/HideShowWidget';
import { setScreenReaderAlert } from '../../helperFunctions/Accessibility';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { brandConfig, checkBrandExists } from '../../configurations/Brands';

function processBackbtn(type, lang, brand) {
  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  if (type === 'category') {
    return `/widget/${brand}/${lang}${isMobile}`;
  }
  if (type === 'section') {
    try {
      return `/widget/${brand}/${lang}/category/${localStorage.getItem(
        'category'
      )}${isMobile}`;
    } catch (e) {
      return `/widget/${brand}/${lang}${isMobile}`;
    }
  }

  if (type === 'articles') {
    try {
      return `/widget/${brand}/${lang}/section/${localStorage.getItem(
        'section'
      )}${isMobile}`;
    } catch (e) {
      return `/widget/${brand}/${lang}${isMobile}`;
    }
  }
  if (lang === undefined) {
    return '/widget';
  }
  return `/widget/${brand}/${lang}${isMobile}`;
}

// Check if brand exists. Only display search bar if it exists
function ReturnAlgoliaIfBrand(brand, lang) {
  if (brand !== undefined) {
    return <AlgoliaSearch brand={brand} lang={lang} />;
  }
}

function WidgetTopBar(props) {
  let { lang } = props;
  const { brand, type, isMobile, filteringByText } = props;
  const history = useHistory();
  if (lang === undefined) {
    lang = 'en';
  }

  const renderHomeTooltip = (toolTipProps) => (
    <Tooltip id="button-tooltip" {...toolTipProps}>
      {t('LinkDescription_Widget_Home', null, lang)}
    </Tooltip>
  );

  const topStyling = { backgroundColor: '#6cc72b', color: 'black' };

  if (checkBrandExists(brand)) {
    topStyling.backgroundColor = brandConfig[brand].widgetMode.theme.backgroundColor;
    topStyling.color = brandConfig[brand].widgetMode.theme.color;
  } else {
    topStyling.backgroundColor = '#D52B1E';
    topStyling.color = 'rgba(255, 255, 255, 1)';
  }

  const homeBackBtnStyle = { ...topStyling };
  if (isMobile) homeBackBtnStyle.marginRight = '10px';

  const prev = processBackbtn(type, lang, brand);

  function returnHelpCentreTitle() {
    return ((brand) && brand.toLowerCase() === 'gameplayshop') ? 'Help Center' : t('Widget_Help_Centre', null, lang);
  }

  // Hide back button when on category page
  function shouldHideBackBtn() {
    if (type === undefined && lang === undefined && brand === undefined) {
      return <span className="widgetEmptySpan" />;
    }

    if (type === undefined && lang === undefined && brand !== undefined) {
      return <span className="widgetEmptySpan" />;
    }

    // list all categories
    if (type === undefined && brand !== undefined) {
      return (isMobile ? <div id="widget-title-mobile" className={`${(isMobile) ? 'mobile-title' : ''} center-text`}>{returnHelpCentreTitle()}</div> : <span className="widgetEmptySpan" />);
    }

    if (lang !== undefined && type === undefined) {
      return <span className="widgetEmptySpan" />;
    }
    if (type === 'contactselect' || type === 'contactus') {
      return null;
    }

    return (
      <button
        className="widget-back-btn"
        type="button"
        aria-label={t('LinkDescription_Widget_Go_Back', null, lang)}
        onClick={() => {
          setScreenReaderAlert(
            t('ScreenReaderAlert_Widget_Go_Back', null, lang)
          );
          logAmpEvent(event.USER_CLICKED_WIDGET_BACK_BTN);
          history.push(prev);
        }}
        style={homeBackBtnStyle}
      >
        <span id="backButtonFlexSpan">
          <i className="fas fa-arrow-left flex-align-vertical" style={homeBackBtnStyle} />
          {isMobile ? <div id="widget-title-mobile" className={`${(isMobile) ? 'mobile-title' : ''}`}>{returnHelpCentreTitle()}</div> : null}
          {(isMobile) ? null : <span className="tooltipText-back" role="tooltip">{t('ScreenReaderAlert_Widget_Go_Back', null, lang)}</span>}
        </span>

      </button>
    );
  }

  function shouldShowHomeBtn() {
    const mobileApp = (isMobile) ? '?mobileapp' : '';

    if (type === 'contactselect' || type === 'contactus') {
      return (
        <OverlayTrigger
          placement="bottom"
          rootClose
          delay={{ show: 250, hide: 250 }}
          overlay={renderHomeTooltip}
        >
          <Link
            className="widget-home-btn"
            style={homeBackBtnStyle}
            aria-label={t('LinkDescription_Widget_Home', null, lang)}
            onClick={() => {
              setScreenReaderAlert(
                t('ScreenReaderAlert_Widget_Home', null, lang)
              );
              logAmpEvent(event.USER_CLICK_WIDGET_HOME_BTN);
            }}
            to={`/widget/${brand}/${lang}${mobileApp}`}

          >
            <span id="backButtonFlexSpan">
              <i className="fas fa-home-lg-alt widget-home-icon flex-align-vertical" style={homeBackBtnStyle} />
              {isMobile ? <div id="widget-title-mobile" className={`${(isMobile) ? 'mobile-title' : ''}`}>{returnHelpCentreTitle()}</div> : null}
            </span>
          </Link>
        </OverlayTrigger>
      );
    }
    return '';
  }

  function shouldShowMinButton() {
    return (isMobile) ? null :
      (
        <div>
          <button
            className="min-button"
            type="button"
            aria-label={t('LinkDescription_Widget_Minimize', null, lang)}
            style={topStyling}
            onClick={(e) => {
              setScreenReaderAlert(
                t('ScreenReaderAlert_Widget_Minimize', null, lang)
              );
              // only move focus when clicked via keyboard
              hideWidget(document.activeElement === e.target);
              logAmpEvent(event.USER_CLICKED_WIDGET_MINIMIZE);
            }}
          >
            <i className="widget-min-btn fas fa-window-minimize" />
          </button>
          <span className="tooltipText-minimize" role="tooltip">{t('ScreenReaderAlert_Widget_Minimize', null, lang)}</span>
        </div>
      );
  }

  return (
    <div>
      <div id="widget-minimized" style={topStyling}>
        <div id="widget-top-horizontal">
          {shouldHideBackBtn()}
          {shouldShowHomeBtn()}
          {/* For regular widget mode, the title should be centered */}
          {((isMobile) ? null : <div id="widget-min-title" className={`${(isMobile) ? 'mobile-title' : ''}`}>{returnHelpCentreTitle()}</div>)}
          {shouldShowMinButton()}
        </div>
        <label id="filtering-search-label" htmlFor="widgetAlgoliaSearchBox">
          {t('General_How_Can_We_Help', null, lang)}
          <br />
          <span id="filter-searching-articles-text">
            {filteringByText}
          </span>
        </label>
        {ReturnAlgoliaIfBrand(brand, lang)}
      </div>
    </div>
  );
}

export default translate(WidgetTopBar);
